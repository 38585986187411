<template>
  <div class="Winery">
    <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item" v-for="(item, index) in imglist" :key="index" v-if="imglist.length"/>
    <Empty description="暂无数据" v-if="!imglist.length" />
  </div>
</template>

<script>
import { countries } from "@api/tasting";
import { Toast, Empty } from "vant";
import { Image as VanImage } from 'vant';
export default {
    name: "Winery",
    data: function() {
        return {
            id: '',
            imglist: [],
        };
    },
    components: {
        VanImage, Toast, Empty
    },
    mounted: function() {
        this.id=this.$route.query.id;
        this.getcountries();
    },
    methods: {
        getcountries: function() {
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            countries({
                id: this.id,
            }).then(res => {
                Toast.clear();
                this.imglist=res.data;
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
    }
};
</script>

<style scoped lang="scss">

</style>
